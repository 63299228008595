import React from 'react';


function Header() {
  return (
    <div className='header'>
      <img src='https://images.unsplash.com/photo-1630931226611-35cd35ba9917?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjh8fGJsYWNrJTIwYW5kJTIwd2hpdGUlMjB3b21lbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=400&q=60' />

      <h1>Kiss & Tell</h1>
    </div>
  );
}

export default Header;