import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import SearchBar from './SearchBar';
import { Slide } from "react-awesome-reveal";
import { Zoom } from "react-awesome-reveal";

const LipstickList = () => {
  const [lipsticks, setLipsticks] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Fetch lipstick data
    fetch('https://makeup-api.herokuapp.com/api/v1/products.json?product_type=lipstick')
      .then((response) => response.json())
      .then((data) => setLipsticks(data));
  }, []);

  const uniqueBrands = [...new Set(lipsticks.map((lipstick) => lipstick.brand.toLowerCase()))];

  const handleBrandClick = (brand) => {
    setSelectedBrand(brand);
  };

  const filteredBrands = uniqueBrands.filter((brand) => brand.includes(searchTerm.toLowerCase()));

  return (
    <div>
      <Slide direction='down'>
      <Header />
      </Slide>
       <Zoom>
      <div className='align-search'>
      <SearchBar searchTerm={searchTerm} onSearchChange={setSearchTerm} />
      </div>
      </Zoom>
      <ul className="lipstick-list">
        {filteredBrands.sort().map((brand) => (
          <li key={brand}>
            <Link to={`/products/${brand}`} onClick={() => handleBrandClick(brand)}>
              {brand}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LipstickList;
