// Fun Fact this web page was inspired by the qoute "Put a lipstick on it".So the lipstick I decided to put on was a pinterest aesthetic //

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import { Fade } from "react-awesome-reveal";
import { Zoom } from "react-awesome-reveal";
import { Slide } from "react-awesome-reveal";

const ProductListPage = () => {
  const [lipsticks, setLipsticks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { brand } = useParams();
  const handleImageError = (e) => {
     e.target.src = 'https://static.thenounproject.com/png/583402-200.png';
  };
  

  useEffect(() => {
    // Fetch lipstick
    fetch('https://makeup-api.herokuapp.com/api/v1/products.json?product_type=lipstick')
      .then((response) => response.json())
      .then((data) => setLipsticks(data));
  }, []);

  const filteredProducts = lipsticks.filter((lipstick) =>
    lipstick.brand.toLowerCase() === brand.toLowerCase() &&
    lipstick.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Fade>
      <h2>Products for {brand}</h2>
      </Fade>
      <Zoom>
      <div className='align-search-next'>
      <SearchBar searchTerm={searchTerm} onSearchChange={setSearchTerm} />
      </div>
      </Zoom>
      <ul className='product-list'>
        {filteredProducts.map((lipstick) => (
          <li key={lipstick.id}>
            <img src={lipstick.image_link} alt={lipstick.name} 
            onError={handleImageError}
             />
            <p className="lipstick-name">{lipstick.name}</p>
          </li>
        ))}
      </ul>
      
      <div className='back-button'>
      <Link to="/" className='brand-link'>Back</Link>

      </div>
     
    </div>
  );
};

export default ProductListPage;

